import React from "react";
import Slider from "react-slick"

import {testimonial, testimonial2} from "./script";

const testimonials = [
    {
        id: 1,
        image: 'omarsa.png',
        name: 'Omarsa S.A',
        description: 'Nuestro objetivo es ofrecer el verdadero camarón sustentable a nivel mundial, siendo ambiental y socialmente responsable, centrado en la seguridad alimentaria, el bienestar de los animales y la trazabilidad.',
    },{
        id: 2,
        image: 'naturisa.jpeg',
        name: 'NATURISA S.A',
        description: 'Naturisa es una compañía productora de camarón. Nuestro éxito es el resultado de años de dedicación, trabajo duro, y sobre todo, nuestro enfoque continuo en la sostenibilidad social y ambiental de nuestro negocio.',
    },{
        id: 3,
        image: 'quirola.png',
        name: 'GRUPO QUIROLA',
        description: 'Una empresa fuerte, sólida, generadora de riqueza, firmemente posicionada en el entorno nacional e internacional, que haya alcanzado un alto reconocimiento de calidad, con clientes convertidos en amigos leales, un recurso humano comprometido con la empresa, y líder en el desarrollo de la producción bananera, camaronera y cacaotera del Ecuador.',
    },{
        id: 4,
        image: '1.png',
        name: 'EEQL S.A',
        description: '',
    },{
        id: 5,
        image: 'barry.png',
        name: 'BARRY CALLEBAUT S.A',
        description: 'Fabricante líder mundial de productos de chocolate y cacao de alta calidad.',
    },
]

class Testimonial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          nav1: null,
          nav2: null
        };
    }

    componentDidMount() {
        this.setState({
          nav1: this.testimonial,
          nav2: this.testimonial2
        });
    }


    render(){
        return(
            <div className="testimonial-wrapper pt--120 text-center" id="reviews">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="testimonial-activation">
                                <div className="section-title text-center mb--80">
                                    <h2>CLIENTES <span className="theme-color">ESTRATÉGICOS</span></h2>
                                    <img className="image-1" src={require('../assets/images/app/title-icon.png')} alt="App Landing"/>
                                    <img className="image-2" src={require('../assets/images/app/title-icon-2.png')} alt="App Landing"/>
                                    <img className="image-3" src={require('../assets/images/app/title-icon-3.png')} alt="App Landing"/>
                                    <p>Empresas que han confiado en nuestro servicio y se benefician cada dia de todas nuestras actualizaciones.</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">

                                        <Slider {...testimonial} asNavFor={this.state.nav2} ref={slider => (this.testimonial = slider)} className="testimonial-image-slider text-center">
                                        {testimonials.map((item) => <div className="sin-testiImage" key={`image-testimonial-${item.id}`}>
                                                <img src={require(`../assets/images/partners/${item.image}`)} alt={`${item.name}`} />
                                            </div>)}
                                        </Slider>

                                    </div>
                                </div>

                                <Slider {...testimonial2} asNavFor={this.state.nav1} ref={slider => (this.testimonial2 = slider)} className="testimonial-text-slider text-center">
                                {testimonials.map((item) => <div className="sin-testiText"  key={`detail-testimonial-${item.id}`}>
                                    <h2>{item.name}</h2>
                                    <div className="client-rating">
                                        <i className="zmdi zmdi-star color"></i>
                                        <i className="zmdi zmdi-star color"></i>
                                        <i className="zmdi zmdi-star color"></i>
                                        <i className="zmdi zmdi-star color"></i>
                                        <i className="zmdi zmdi-star color"></i>
                                        {/* <i className="zmdi zmdi-star-half color"></i> */}
                                    </div>
                                    <p>{item.description}</p>
                                </div>)}
                                </Slider>
                                    
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
 export default Testimonial;







