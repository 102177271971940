import React ,  { Component } from "react";

class About extends Component{
    render(){
        return(
            <div className={`app-about ${this.props.horizontalabout}`}  id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2>ACERCA DE <span className="theme-color">MAPPTRIX</span></h2>
                                <img className="image-1" src={require('../assets/images/app/title-icon.png')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/title-icon-2.png')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/title-icon-3.png')} alt="App Landing"/>
                                <p>Realice sus labores a diario sin conexión a internet, traza una posición o un marcador, con datos enriquecidos como fotos, videos y descripciones. Compara y mide distancias o áreas de su recorrido en el mapa.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-5 offset-lg-1 mt--40">
                            <div className="about-thumbnail mr--35">
                                <img className="image-1" src={require('../assets/images/app/map_app_poly.jpg')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/map_app_poly.jpg')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/map_app_poly.jpg')} alt="App Landing"/>
                            </div>
                        </div>
                        <div className="col-lg-6 mt--40">
                            <div className="about-content">
                                <h2 className="title">APP MÓVIL DE <span className="theme-color">UBICACIÓN</span></h2>
                                <p>A través del GPS de su dispositivo móvil se delimita el área de trabajo, que le permite, como usuario, registrar geográficamente todas las características de referencia que necesite incorporar en el mapa que está creando y facilite la orientación del sitio o las mejoras que sean necesarias incluir.</p>
                                {/* <p>mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the </p> */}
                                <div className="about-buttons">
                                    <button type="button" className="button-default button-olive button-border">Descargar</button>
                                    <a className="button-default button-border" href="/" role="button">Learn more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;











