import React ,  { Component } from "react";



class Feature extends Component{
    render(){
       let data = [
           
        {
            icon: 'zmdi zmdi-8tracks',
            title: 'TRAYECTORIA',
            desc: 'La unión de varios puntos, para crear una línea marcada del trayecto recorrido. Permite cargar archivos multimedia, asignar color y tamaño del objeto, una breve descripción de referencia y registra la fecha y hora de la última modificación.'
        },

        {
            icon: 'zmdi zmdi-pin',
            title: 'MARCADOR',
            desc: 'Resalta un punto en el mapa que puede ser de interés o de referencia para localizar algo dentro de las coordenadas.'
        },

        {
            icon: 'zmdi zmdi-square-o',
            title: 'POLÍGONO',
            desc: 'Delimita un área de trabajo uniendo varias líneas hasta formar un polígono desde un punto a otro.'
        },

        {
            icon: 'zmdi zmdi-minus',
            title: 'LÍNEA',
            desc: 'Marca un trayecto de un punto ‘A’ a un punto ‘B’ formando una distancia de línea recta.'
        }
       ] 

       let DataList = data.map((val , i) => {
           return(
               /* Start Single Feature */
               <div className="feature" key={i}>
                   <div className="feature-icon">
                       <i className={`${val.icon}`}/>
                   </div>
                   <div className="content">
                       <h4 className="title">{val.title}</h4>
                       <p className="desc">{val.desc}</p>
                   </div>
               </div>
               /* End Single Feature */
           )
       })

       return(
           <div className= {`feature-area feature-bg-image pb--50 ${this.props.horizontalfeature}`} id="features">
               <div className="container">
                   <div className="row">
                       <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2>OBJETOS <span className="theme-color">DISPONIBLES</span></h2>
                                <img className="image-1" src={require('../assets/images/app/title-icon.png')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/title-icon-2.png')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/title-icon-3.png')} alt="App Landing"/>
                                <p>Mapptrix permite agregar cuatro tipos de objetos al mapa de geolocalización para registrar puntos de interés dentro del mapa.</p>
                            </div>
                       </div>
                   </div>
                   <div className="row mt--30">
                       <div className="col-lg-7 offset-lg-5">
                            <div className="feature-list">
                                {DataList}
                            </div>
                       </div>
                   </div>
               </div>
           </div>
        )
    }
}

export default Feature;
